<template>
    <BaseSection
        v-if="items.length"
        class="section--blog-select"
        :class="{ 'section--large-header': largeHeader }"
        data-width="none"
        :alignment="headerAlignmentMapped"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>

        <template
            v-if="subTitle"
            v-slot:subtitle
        >
            {{ subTitle }}
        </template>

        <ImageCards
            :items="items"
            :size="featured ? 'large' : 'small'"
        />
    </BaseSection>
</template>

<script setup>
import getBlogPageEntries from '~/graphql/queries/getBlogPageEntries.graphql';
import { BLOG_TAGS } from '~/constants/categories-and-tags.js';
import { MAX_AMOUNT_OF_ARTICLES } from '~/constants/general.js';

const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => { return {}; }
    }
});

const { content } = toRefs(props);

const tagObjects = computed(() => content.value?.blogTags ?? []);
const tagIds = computed(() => tagObjects.value?.map(({ id }) => id));
const itemsSelected = computed(() => content.value?.blogSelect ?? []);
const featured = computed(() => content.value?.featured ?? false);
const subTitle = computed(() => content.value?.subTitle ?? null);
const header = computed(() => content.value?.header ?? null);
const largeHeader = computed(() => content.value?.largeHeader ?? true);
const headerAlignment = computed(() => content.value?.headerAlignment ?? true);
const headerAlignmentMapped = computed(() => {
    if (headerAlignment.value) {
        return 'center';
    }

    return 'left';
});

const { $i18n } = useNuxtApp();
const siteHandle = $i18n?.localeProperties?.value?.craftSiteHandle;
const variables = computed(() => {
    return {
        siteHandle,
        relatedToTags: [{ group: BLOG_TAGS, id: tagIds.value }],
        limit: MAX_AMOUNT_OF_ARTICLES,
    };
});

const { data } = await useAsyncQuery({
    query: getBlogPageEntries,
    variables
});

const itemsFromTags = computed(() => data.value?.entries ?? []);
const items = computed(() => {
    // Get blog and event pages either from tags or those selected in the flexible section
    if (tagObjects.value.length && itemsFromTags.value.length) {
        return itemsFromTags.value;
    }

    return itemsSelected.value;
});
</script>
